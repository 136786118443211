.react-confirm-alert-body {
  background-color: #d5e2f5 !important;
  text-align: center !important;
}

.react-confirm-alert-button-group {
  display: flow-root !important;
}

.ReactTable .rt-td, .ReactTable .rt-thead .rt-th {
  text-align: center !important;
}

.info {
  border: none !important;
  border-left: 3px solid orange !important;
  border-radius: unset !important;
  background-color: #f9f4ea !important;
  padding: 10px;
}

.reset-pass {
  background-color: transparent;
  border: 1px solid gray !important;
  margin-bottom: 10px;
}

.remove-sys {
  background-color: #ef2f2f;
  color: #ffffff;
  margin-bottom: 10px;
}

.infopara {
  margin-top: 15px;
}

.info2 {
  border: none !important;
  border-left: 3px solid #00c4ff !important;
  border-radius: unset !important;
  background-color: #e6f3f7 !important;
  padding: 10px;
}

.role-info {
  line-height: 1.3 !important;
}

.del-icon {
  color: red;
  cursor: pointer;
}

.edit-icon {
  color: #14af1f;
  cursor: pointer;
}

.view-icon {
  color: #0f7aa2;
  cursor: pointer;
}

.tot-count {
  margin-top: 5px;
}

.tot-count span {
  top: 5px !important;
  padding: 10px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

.active-icon {
  color: #14af1f;
}

.inactive-icon {
  color: red;
}

.coupon-check {
  margin-bottom: 20px;
}

.align-right{
  text-align: right;
}

.dis-pre {
  float: left;
  padding: 7px;
  background-color: gray;
  color: white;
  padding-bottom: 8px;
  border-radius: 5px;
}

.dis-val {
  width: 80px !important;
  margin-top: -35px;
}

.col-val {
  padding-left: 85px !important;
}

.camp {
  margin-top: 40px;
}

.add-new-btn {
  margin-top: 8px;
}

.ladda-spinner div {
  margin-top: -3px !important;
}

.promo-submit {
  margin-top: 28px;
}

.img-responsive {
  height: auto;
  width: 220px;
  cursor: pointer;
}

#file {
  display: none;
}

.list_image {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.disc {
  float: right;
}

.filter-width{
  width:100%;
}

.user-addr {
  background-color: #f9f9f9;
  padding: 5px;
  margin-bottom: 10px;
}

.def-th {
  color:orangered !important;
}
.dark-th {
  color: black !important;
}

.Select.is-focused:not(.is-open)>.Select-control {
  border-color: orange !important;
}

.order-table .rt-tr-group {
  cursor: pointer;
}

.launch-img {
  width: 170px !important;
}

.ril-image-current {
  transform:none !important;
  width:700px;
}

.modal-body .bg-newcol {
  background: -webkit-linear-gradient(to right, #FB6446, #FB505F);
  background: linear-gradient(to right, #FB6446, #FB505F);
  color: #ffffff;
}

/*-----------------------------------------------------------
Zoomin Theme
-----------------------------------------------------------*/
body{
  background-color: #edf0f3 !important;
}

.admin-theme .app-header {
  /*background: -webkit-linear-gradient(to right, #ffffff, #f78c63);
  background: linear-gradient(to right,#ffffff, #f78c63);*/
  border: none;
}

.admin-theme .sidebar .nav-link.active {
  background: -webkit-linear-gradient(to right, #FB6446, #FB505F);
  background: linear-gradient(to right, #FB6446, #FB505F);
}

.admin-theme .sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}

.admin-theme .sidebar .nav-icon {
  margin-right: 25px !important;
}

.admin-theme .sidebar {
  background: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 1px;
}

.admin-theme .sidebar .nav-link {
  color: #414142;
}

.admin-theme .sidebar .nav-link:hover {
  color: #fff;
  background: #fba265;
}

.admin-theme .breadcrumb {
  border-bottom: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 25px 0 5px 30px;
  background-color: #edf0f3;
  letter-spacing: 1px;
}

.admin-theme .breadcrumb-item + .breadcrumb-item::before, .admin-theme .breadcrumb-item a, .admin-theme .breadcrumb li {
  color: #4b4b4b;
}

.admin-theme .breadcrumb li:last-child {
  color: #2ea9c5;
}

.admin-theme .card {
  border: none;
}

.admin-theme .card-header {
  border-bottom: none;
  background-color: #ffffff;
}

.admin-theme .bg-faded {
  background-color: #e0f4fb;
  color: #16a6bd !important;
}

.admin-theme .bg-faded-reverse {
  background-color: #16a6bd;
  color: #e0f4fb !important;
}

.admin-theme .bg-faded-reverse b {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
}

.admin-theme .card-header {
  font-size: 20px;
  color: #6b6767;
}

.admin-theme .app-footer {
  /*box-shadow: 1px 1px 10px gray;*/
}

.admin-theme .chart-wrapper {
  height: 26px !important;
}

.admin-theme .input-box-login {
  border: none !important;
  box-shadow: 0 9px 25px #e6e9ec;
}

.admin-theme .forgot-pass {
  font-weight: 600;
}

.admin-theme .bg-indigo-gradient {
  background: -webkit-linear-gradient(to right, #040f5a, #3657fb);
  background: linear-gradient(to right, #040f5a, #3657fb);
}

.admin-theme .bg-darkpurple-gradient {
  background: -webkit-linear-gradient(to right, #34024e, #8402fb);
  background: linear-gradient(to right, #34024e, #8402fb);
}

.admin-theme .bg-darkred-gradient {
  background: -webkit-linear-gradient(to right, #630904, #e43131);
  background: linear-gradient(to right, #630904, #e43131);
}

.admin-theme .bg-blue-gradient {
  background: -webkit-linear-gradient(to right, #022a5d, #009af5);
  background: linear-gradient(to right, #022a5d, #009af5);
}

.admin-theme .bg-pink-gradient {
  background: -webkit-linear-gradient(to right, #370344, #e72aeb);
  background: linear-gradient(to right, #370344, #e72aeb);
}

.admin-theme .bg-dark-gradient {
  background: -webkit-linear-gradient(to right, #15163c, #364ade);
  background: linear-gradient(to right, #15163c, #364ade);
}

.admin-theme .bg-reddark-gradient {
  background: -webkit-linear-gradient(to right, #000000, #610404);
  background: linear-gradient(to right, #000000, #610404);
}

.admin-theme .bg-cyan-gradient {
  background: -webkit-linear-gradient(to right, #04464e, #28c4d4);
  background: linear-gradient(to right, #04464e, #28c4d4);
}

.admin-theme .bg-green-gradient {
  background: -webkit-linear-gradient(to right, #033800, #339e31);
  background: linear-gradient(to right, #033800, #339e31);
}

.admin-theme .bg-orange-gradient {
  background: -webkit-linear-gradient(to right, #cd8411, #df4f04);
  background: linear-gradient(to right, #cd8411, #df4f04);
}

.admin-theme .bg-red-gradient {
  background: -webkit-linear-gradient(to right, #580417, #f9555e);
  background: linear-gradient(to right, #580417, #f9555e)
}

.admin-theme .bg-newcol {
  background: -webkit-linear-gradient(to right, #FB6446, #FB505F);
  background: linear-gradient(to right, #FB6446, #FB505F);
  color: #ffffff;
}

.admin-theme .bg-login {
  background-color: #fafdff;
}

.admin-theme .submit-btn:hover {
  color: #ffffff;
  opacity: 0.8;
}

.admin-theme .fp {
  color: gray;
}

.admin-theme .color-footer {
  color: #fe7837;
  font-weight: 700;
  text-transform: uppercase;
}

.admin-theme .left-column{
  width: 106%;
  left: -10px;
}

.admin-theme .right-column{
  width: 106%;
  left: -10px;
}

.admin-theme .font-icon {
  font-size: 40px;
  margin-top: 15px;
  color: #2ad0c8;
}

.admin-theme .card-text .text-value {
  font-size: 2.1em;
  font-weight: 700;
  color: #807c7c;
}

.admin-theme .card-text div:last-child {
  font-weight: 500;
  color: #908d8d;
}

.admin-theme .sub-order {
  text-decoration: none;
  color: #ff5c18;
}

.admin-theme .order-det {
  padding: 5px;
}

.admin-theme .setting-icon {
  color: #1e0f0f;
}

.admin-theme .validation-message {
  color: #f86c6b !important;
}


/*-----------------------------------------------------------
Zoomin Dark Theme
-----------------------------------------------------------*/
.zoomin-dark-theme .app-header {
  background: -webkit-linear-gradient(to right, #140603, #964b45);
  background: linear-gradient(to right,#140603, #964b45);
  border: none;

}

.zoomin-dark-theme .sidebar .nav-link.active {
  background: -webkit-linear-gradient(to right, #6a271e, #a54531);
  background: linear-gradient(to right, #6a271e, #a54531);
}

.zoomin-dark-theme .sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}

.zoomin-dark-theme .sidebar {
  background: #130402;
}

.zoomin-dark-theme .sidebar .nav-link {
  color: #aaaaab;
}

.zoomin-dark-theme .sidebar .nav-link:hover {
  color: #fff;
  background: #502020;
}

.zoomin-dark-theme .breadcrumb {
  border-bottom: none;
  box-shadow: 1px 1px 5px gray;
  background-color: rgb(51, 25, 25);
}

.zoomin-dark-theme .breadcrumb-item + .breadcrumb-item::before, .zoomin-dark-theme .breadcrumb-item a, .zoomin-dark-theme .breadcrumb li {
  color: #cbcbcb;
}

.zoomin-dark-theme .card {
  border: none;
  box-shadow: 0 0 15px gray;
}

.zoomin-dark-theme .card-header {
  border-bottom: none;
  background-color: #574640;
  color: #fff3cd;
}

.zoomin-dark-theme .app-footer {
  box-shadow: 1px 1px 10px gray;
  background-color: #574640;
}

.zoomin-dark-theme .chart-wrapper {
  height: 26px !important;
}

.zoomin-dark-theme .input-box-login {
  border: none !important;
  box-shadow: 0 9px 25px #e6e9ec;
}

.zoomin-dark-theme .forgot-pass {
  font-weight: 600;
}

.zoomin-dark-theme .bg-indigo-gradient {
  background: -webkit-linear-gradient(to right, #040f5a, #030306);
  background: linear-gradient(to right, #040f5a, #030306);
}

.zoomin-dark-theme .bg-darkpurple-gradient {
  background: -webkit-linear-gradient(to right, #34024e, #030306);
  background: linear-gradient(to right, #34024e, #030306);
}

.zoomin-dark-theme .bg-darkred-gradient {
  background: -webkit-linear-gradient(to right, #630904, #030306);
  background: linear-gradient(to right, #630904, #030306);
}

.zoomin-dark-theme .bg-blue-gradient {
  background: -webkit-linear-gradient(to right, #022a5d, #030306);
  background: linear-gradient(to right, #022a5d, #030306);
}

.zoomin-dark-theme .bg-pink-gradient {
  background: -webkit-linear-gradient(to right, #370344, #030306);
  background: linear-gradient(to right, #370344, #030306);
}

.zoomin-dark-theme .bg-dark-gradient {
  background: -webkit-linear-gradient(to right, #15163c, #030306);
  background: linear-gradient(to right, #15163c, #030306);
}

.zoomin-dark-theme .bg-reddark-gradient {
  background: -webkit-linear-gradient(to right, #000000, #030306);
  background: linear-gradient(to right, #000000, #030306);
}

.zoomin-dark-theme .bg-cyan-gradient {
  background: -webkit-linear-gradient(to right, #04464e, #030306);
  background: linear-gradient(to right, #04464e, #030306);
}

.zoomin-dark-theme .bg-green-gradient {
  background: -webkit-linear-gradient(to right, #033800, #030306);
  background: linear-gradient(to right, #033800, #030306);
}

.zoomin-dark-theme .bg-orange-gradient {
  background: -webkit-linear-gradient(to right, #cd8411, #030306);
  background: linear-gradient(to right, #cd8411, #030306);
}

.zoomin-dark-theme .bg-red-gradient {
  background: -webkit-linear-gradient(to right, #580417, #030306);
  background: linear-gradient(to right, #580417, #030306)
}

.zoomin-dark-theme .bg-newcol {
  background: -webkit-linear-gradient(to right,  #6a271e, #a54531);
  background: linear-gradient(to right,  #6a271e, #a54531);
  color: #ffffff;
}

.zoomin-dark-theme .bg-login {
  background-color: #fafdff;
}

.zoomin-dark-theme .submit-btn:hover {
  color: #ffffff;
  opacity: 0.8;
}

.zoomin-dark-theme .fp {
  color: gray;
}

.zoomin-dark-theme .color-footer {
  color: #fee4d7;
}

.zoomin-dark-theme .app-footer span{
  color: #fea858;
}

.zoomin-dark-theme .left-column{
  width: 106%;
  left: -10px;
}

.zoomin-dark-theme .right-column{
  width: 106%;
  left: -10px;
}

.zoomin-dark-theme .font-icon {
  font-size: 40px;
  margin-top: 5px;
}

.zoomin-dark-theme .sub-order {
  text-decoration: none;
  color: #ff5c18;
}

.zoomin-dark-theme .order-det {
  padding: 5px;
}

.zoomin-dark-theme .setting-icon {
  color: #e8e2de;
}

.zoomin-dark-theme .validation-message {
  color: #341919 !important;
}

.zoomin-dark-theme .dropdown-header {
  color : #ffffff !important;
}

.zoomin-dark-theme .app-header .navbar-nav .dropdown-menu-right{
  background-color: #030306;
}

.zoomin-dark-theme .app-header .navbar-nav .dropdown-menu-right .dropdown-item{
  color : #e8e2de !important;
  border:none;
}

.zoomin-dark-theme .app-header .navbar-nav .dropdown-menu-right .dropdown-item:hover{
  color : #5d5d5d !important;
}

.zoomin-dark-theme .dark-th {
  color: #5d5d5d !important;
}


/*----------------------------------------------------------------------------------------------*/
/*Zoomin Light Theme*/
/*----------------------------------------------------------------------------------------------*/
.zoomin-light-theme .bg-indigo-gradient {
  background: -webkit-linear-gradient(to right, #040f5a, #a8a8a8);
  background: linear-gradient(to right, #040f5a, #a8a8a8);
}

.zoomin-light-theme .bg-darkpurple-gradient {
  background: -webkit-linear-gradient(to right, #34024e, #a8a8a8);
  background: linear-gradient(to right, #34024e, #a8a8a8);
}

.zoomin-light-theme .bg-darkred-gradient {
  background: -webkit-linear-gradient(to right, #630904, #a8a8a8);
  background: linear-gradient(to right, #630904, #a8a8a8);
}

.zoomin-light-theme .bg-blue-gradient {
  background: -webkit-linear-gradient(to right, #022a5d, #a8a8a8);
  background: linear-gradient(to right, #022a5d, #a8a8a8);
}

.zoomin-light-theme .bg-pink-gradient {
  background: -webkit-linear-gradient(to right, #370344, #a8a8a8);
  background: linear-gradient(to right, #370344, #a8a8a8);
}

.zoomin-light-theme .bg-dark-gradient {
  background: -webkit-linear-gradient(to right, #15163c, #a8a8a8);
  background: linear-gradient(to right, #15163c, #a8a8a8);
}

.zoomin-light-theme .bg-reddark-gradient {
  background: -webkit-linear-gradient(to right, #000000, #a8a8a8);
  background: linear-gradient(to right, #000000, #a8a8a8);
}

.zoomin-light-theme .bg-cyan-gradient {
  background: -webkit-linear-gradient(to right, #04464e, #a8a8a8);
  background: linear-gradient(to right, #04464e, #a8a8a8);
}

.zoomin-light-theme .bg-green-gradient {
  background: -webkit-linear-gradient(to right, #033800, #a8a8a8);
  background: linear-gradient(to right, #033800, #a8a8a8);
}

.zoomin-light-theme .bg-orange-gradient {
  background: -webkit-linear-gradient(to right, #cd8411, #a8a8a8);
  background: linear-gradient(to right, #cd8411, #a8a8a8);
}

.zoomin-light-theme .bg-red-gradient {
  background: -webkit-linear-gradient(to right, #580417, #a8a8a8);
  background: linear-gradient(to right, #580417, #a8a8a8)
}

.zoomin-light-theme .bg-newcol {
  background: -webkit-linear-gradient(to right, #6aa8a5, #8197b9);
  background: linear-gradient(to right, #6aa8a5, #8197b9);
  color: #ffffff;
}

.zoomin-light-theme .chart-wrapper {
  height: 26px !important;
}

.zoomin-light-theme .font-icon {
  font-size: 40px;
  margin-top: 5px;
}

.zoomin-light-theme .sidebar {
  background: #40474c;
}

.zoomin-light-theme .sidebar .nav-link.active {
  background: -webkit-linear-gradient(to right, #666E73, #40474c);
  background: linear-gradient(to right, #666E73, #40474c);
}

.zoomin-light-theme .card {
  border: none;
  box-shadow: 0 0 15px gray;
}

.zoomin-light-theme .left-column{
  width: 106%;
  left: -10px;
}

.zoomin-light-theme .right-column{
  width: 106%;
  left: -10px;
}

.switch {
  background: #63c2de;
  width: 65px !important;
}
.switch.on .switch-toggle {
  left: 38px !important;
}
.breadcrumb-new {
  margin: 0 -29px 22px !important;
}

.green{
  color: #1BC507;
  padding-left: 20px;
}

.circleOfTypes{
  height:50px;
  border-radius:25px;
  width:50px;
  color: #000;
  text-align: center;
  line-height: 50px;
}

.selectedCircleOfTypes{
  background-color: rgb(204, 255, 203);
  border-color: rgb(37, 155, 36);
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
}

.unSelectedCircleOfTypes{
  background-color:#fff;
  border:1px solid #555;
  cursor: pointer;
}

.editer-content {
  padding-left: 12px;
  padding-right: 12px;
}

.search-text-box{
  width: 50%;
}

.language-drop-down{
  margin-left: 2%;
  width: 15%;
}

.squareOfTypes{
  height:80px;
  width:80px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color:#63c2de;
  border-radius: .25rem!important;
  line-height: 80px;
  font-size: 28px;
}


.squareOfMenu{
  height:100px;
  margin-bottom: 15px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color:#1986ac;
  border-radius: .25rem!important;
  line-height: 100px;
  font-size: 18px;
}

.top50{
  margin-top: 50px;
}

.ladda-button button{
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

.ladda-button button .ladda-spinner{
  transform: translate(17px, 20px);
}

.w-fit{
  width: fit-content !important;
}