.lens-0{
  vertical-align: unset;
  width: 210px;
  /*transition: transform 1s;*/
  /*transform: rotate(50deg);*/
  animation: rotate 3s infinite;
  animation-direction: alternate;
}
.num-4{
  color: rgb(234, 166, 36);
  font-size: 300px;
  font-weight: bold;
  margin-right: 30px;
  margin-left: 20px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);;
  }
  100% {
    transform: rotate(50deg);;
  }
}
@media (max-width: 720px) {
  .num-4{
    font-size: 120px;
  }
  .lens-0 {
    width: 100px;
  }
}
